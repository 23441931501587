<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="leasingAnalysis"
  >
    <template v-if="hasPermission">
      <div
        class="select-component native-input-solid"
        v-show="showStep"
      >
        <el-select
          v-model="decisionsStep"
          placeholder="请选择"
          class="input-solid"
          popper-class="w-block-select-down yellow-select-panel"
          @change="decisionsChange"
        >
          <el-option
            v-for="item in decisionsStepList"
            :key="item.decisionStepId"
            :label="item.decisionStepName"
            :value="item.decisionStepId"
          ></el-option>
        </el-select>
      </div>
      <section class="lease-content d-flex content-between">
        <div class="lease-analysis">
          <p class="title">租赁决策分析</p>
          <div
            class="d-flex lease-analysis-content content-between"
            v-if="leaseAnalysis"
          >
            <div>
              <div id="reachChart"></div>
              <ul class="data-list">
                <li><span>收入目标</span>{{leaseAnalysis.incomeTarget}}元</li>
                <li><span>已达成收入</span>{{leaseAnalysis.incomeComplete}}元</li>
                <li><span>租金收入完成率</span>{{leaseAnalysis.rentCompleteRate}}%</li>
                <li><span>管理费收入完成率</span>{{leaseAnalysis.costCompleteRate}}%</li>
              </ul>
            </div>
            <div class="right-data">
              <div class="data-detail-list">
                <div>
                  <span>实际租金收益</span>
                  <p>{{leaseAnalysis.receivedRent}}元</p>
                </div>
                <el-progress
                  type="circle"
                  color="#F89407"
                  :width="61"
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="Number(leaseAnalysis.receivedRentRate)"
                ></el-progress>
              </div>
              <div class="data-detail-list">
                <div>
                  <span>实际管理费收益</span>
                  <p>{{leaseAnalysis.receivedCost}}元</p>
                </div>
                <el-progress
                  type="circle"
                  color="#F89407"
                  :width="61"
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="Number(leaseAnalysis.receivedCostRate)"
                ></el-progress>
              </div>
              <div class="data-detail-list">
                <div>
                  <span>实际总收益</span>
                  <p>{{leaseAnalysis.receivedIncome}}元</p>
                </div>
                <el-progress
                  type="circle"
                  color="#F89407"
                  :width="61"
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="Number(leaseAnalysis.receivedIncomeRate)"
                ></el-progress>
              </div>
            </div>
          </div>
        </div>
        <div class="lease-trend">
          <p class="title">租赁决策达成趋势</p>
          <div class="lease-trend-content">
            <div class="d-flex">
              <el-radio-group
                v-model="trend"
                @change="trendChange"
              >
                <el-radio :label="1">按收入</el-radio>
                <el-radio :label="2">按面积</el-radio>
              </el-radio-group>
            </div>
            <div id="trendChart"></div>
          </div>
        </div>
      </section>
      <section class="index-complete">
        <p class="title">分指标达成情况</p>
        <div class="d-flex content-between">
          <ul
            class="rent-list"
            v-if="indexComplete.length > 0"
          >
            <li
              v-for="(item, inx) in indexComplete"
              :key="inx"
            >
              <div class="d-flex content-between">
                <div class="rent">
                  <p><span>{{indexCompleteName[inx]}}</span>{{item.berthAvgRent}}</p>
                  <p class="d-flex f-wrap">
                    <span>较目标场均租金：</span>{{item.berthAvgRentCompare}}
                    <i
                      v-if="Number(item.berthAvgRentCompareRate) !== 0"
                      :class="Number(item.berthAvgRentCompareRate) < 0 ? 'down' : 'up'"
                    >{{item.berthAvgRentCompareRate}}%</i>
                    <i v-else>-</i>
                  </p>
                </div>
                <div class="native-input-solid">
                  <el-select
                    v-if="inx === 0"
                    v-model="indexType"
                    @change="indexCompleteChange($event, inx + 1)"
                    class="input-solid"
                    popper-class="w-block-select-down yellow-select-panel"
                  >
                    <el-option
                      v-for="item in indexTypeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-else-if="inx === 1"
                    v-model="indexIndustry"
                    @change="indexCompleteChange($event, inx + 1)"
                    class="input-solid"
                    popper-class="w-block-select-down yellow-select-panel"
                  >
                    <el-option
                      v-for="item in indexIndustryList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-else
                    v-model="indexFloor"
                    @change="indexCompleteChange($event, inx + 1)"
                    class="input-solid"
                    popper-class="w-block-select-down yellow-select-panel"
                  >
                    <el-option
                      v-for="item in indexFloorList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <completeChart :dataArr="item"></completeChart>
            </li>
          </ul>
          <div class="graph">
            <div class="d-flex content-between tool-bar">
              <p class="second-title">招商完成率与租决完成率趋势</p>
              <div class="native-input-solid">
                <el-select
                  v-model="indexChart"
                  placeholder="请选择"
                  class="input-solid"
                  popper-class="w-block-select-down yellow-select-panel"
                  @change="indexOptionChange"
                >
                  <el-option
                    v-for="item in indexChartOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex">
              <div id="graphChart"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="brand-complete">
        <p class="title">品牌招商达成情况</p>
        <div class="d-flex content-between brand-complete-content">
          <div>
            <p class="second-title">落位计划达成情况</p>
            <template v-if="berthPlan.length > 0">
              <div
                class="data-detail-list"
                v-for="(item, inx) in berthPlan"
                :key="inx"
              >
                <div>
                  <span>{{item.type === 0 ? '实际招商品牌级次低于规划品牌' : item.type === 1 ? '实际招商品牌级次于规划品牌持平' : '实际招商品牌级次高于规划品牌'}}</span>
                  <p>{{item.nums}}个</p>
                </div>
                <el-progress
                  type="circle"
                  color="#F89407"
                  :width="61"
                  :stroke-width="8"
                  stroke-linecap="butt"
                  :percentage="Number(item.rate)"
                ></el-progress>
              </div>
            </template>
            <div
              v-else
              style="width: 371px"
            >
              <div class="no-recommend-brand">
                <img src="@/assets/images/public/not-plandata.png">
                <p>暂无相关数据</p>
              </div>
            </div>
          </div>
          <div class="right-chart-data">
            <p class="second-title">品牌策略达成情况</p>
            <div id="columnarChart"></div>
          </div>
        </div>
      </section>
    </template>
    <div
      class="simpleInfoPanel"
      v-if="!hasPermission"
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import completeChart from './components/completeChart'
import { powerAuth, toThousands } from '@/utils'
export default {
  name: 'leasingAnalysis',
  data () {
    return {
      hasPermission: true,
      loading: false,
      trend: 1,
      decisionsStep: null,
      decisionsStepList: [],
      indexType: 1,
      indexTypeList: [
        { value: 1, name: '主力店' },
        { value: 2, name: '次主力店' },
        { value: 0, name: '普通店' }
      ],
      indexIndustry: null,
      indexIndustryList: [],
      indexFloor: null,
      indexFloorList: [],
      indexCompleteName: ['场均租金：', '业态场均租金：', '楼层场均租金：'],
      indexComplete: [],
      leaseAnalysis: null,
      indexChart: 1,
      indexChartOptions: [
        { value: 1, name: '按主次力店' },
        { value: 2, name: '按业态' },
        { value: 3, name: '按楼层' }
      ],
      berthPlan: [],
      showStep: false
    }
  },
  components: {
    completeChart
  },
  props: {
    projectId: {
      type: Number
    },
    decisionId: {
      type: Number
    }
  },
  mounted () {
    if (!powerAuth(209, 'show')) {
      this.hasPermission = false
    } else {
      this.getDecisionsStep()
    }
  },
  methods: {
    // 品牌策略达成情况
    getBrandStrategy () {
      const params = {
        decisionStepId: this.decisionsStep,
        projectId: this.projectId
      }
      this.loading = true
      this.axios.get(api.brandStrategy, { params: params }).then(res => {
        this.loading = false
        setTimeout(() => {
          this.showStep = true
        }, 200)
        this.$nextTick(() => {
          this.initColumnarChart(res.data.data)
        })
      })
    },
    // 落位计划达成情况
    getReachInfo () {
      this.loading = true
      this.axios.get(api.reachInfoBerthPlan, { params: { decisionStepId: this.decisionsStep } }).then(res => {
        this.loading = false
        this.berthPlan = res.data.data
      })
    },
    // 招商完成率与租决完成率趋势
    getCompleteRateTrend () {
      const params = {
        decisionStepId: this.decisionsStep,
        type: this.indexChart
      }
      this.loading = true
      this.axios.get(api.completeRateTrend, { params: params }).then(res => {
        this.loading = false
        this.$nextTick(() => {
          setTimeout(() => {
            this.initGraphChart(res.data.data)
          }, 500)
        })
      })
    },
    // 获取一级业态 / 楼层
    getIndustryAndFloor () {
      this.loading = true
      this.axios.post(api.getListIndustry1).then(res => {
        this.indexIndustryList = res.data.data
        this.indexIndustry = res.data.data[0].id
        return res.data.data
      }).then(item => {
        this.axios.post(api.getDecisionFloorList, { decisionId: this.decisionId }).then(res => {
          this.loading = false
          this.indexFloorList = res.data.data.map(item => {
            const obj = {
              name: item.buildingName,
              value: item.buildingCode,
              fcode: item.fcode
            }
            return obj
          })
          this.indexComplete = []
          this.indexFloor = res.data.data[0].buildingCode
          this.getReachInformation(1, this.indexType, this.indexIndustry, res.data.data[0].fcode + ',' + this.indexFloor)
          this.getReachInformation(2, this.indexType, this.indexIndustry, res.data.data[0].fcode + ',' + this.indexFloor)
          this.getReachInformation(3, this.indexType, this.indexIndustry, res.data.data[0].fcode + ',' + this.indexFloor)
        })
      })
    },
    // 获取阶段id
    getDecisionsStep () {
      this.loading = true
      this.axios.get(api.decisionsStep, { params: { decisionId: this.decisionId } }).then(res => {
        this.loading = false
        this.decisionsStepList = res.data.data
        this.decisionsStep = res.data.data[0].decisionStepId
        this.getIncomeAnalysis()
        this.getReachTrend()
        this.getIndustryAndFloor()
        this.getCompleteRateTrend()
        this.getReachInfo()
        this.getBrandStrategy()
      })
    },
    // 租赁决策收入分析
    getIncomeAnalysis () {
      this.loading = true
      this.axios.get(api.incomeAnalysis, { params: { decisionStepId: this.decisionsStep } }).then(res => {
        const data = res.data.data
        this.loading = false
        this.leaseAnalysis = data
        this.leaseAnalysis.incomeTarget = toThousands(data.incomeTarget)
        this.leaseAnalysis.incomeComplete = toThousands(data.incomeComplete)
        this.leaseAnalysis.receivedRent = toThousands(data.receivedRent)
        this.leaseAnalysis.receivedCost = toThousands(data.receivedCost)
        this.leaseAnalysis.receivedIncome = toThousands(data.receivedIncome)
        this.$nextTick(() => {
          this.initReachChart()
        })
      })
    },
    // 租赁决策达成趋势
    getReachTrend () {
      const params = {
        decisionStepId: this.decisionsStep,
        type: this.trend
      }
      this.loading = true
      this.axios.get(api.reachTrend, { params: params }).then(res => {
        this.loading = false
        this.$nextTick(() => {
          this.initTrendChart(res.data.data)
        })
      })
    },
    // 分指标达成情况
    getReachInformation (type, mainType, industry, buildingFloor) {
      // type：类型(1:主次力店，2:按业态，3:按楼层)
      // mainType：主次力店(1:主力店，2:次力店，0:普通店)
      // industry：业态
      // buildingFloor：楼宇楼层(格式：楼宇编码,楼层编码)
      const params = {
        decisionStepId: this.decisionsStep,
        type: type,
        mainType: mainType,
        industry: industry,
        buildingFloor: buildingFloor
      }
      this.loading = true
      this.axios.get(api.reachInformation, { params: params }).then(res => {
        this.loading = false
        this.$set(this.indexComplete, type - 1, res.data.data)
      })
    },
    // 招商完成率与租决完成率趋势 图表数据类型变更
    indexOptionChange () {
      this.getCompleteRateTrend()
    },
    // 阶段变更
    decisionsChange () {
      this.getIncomeAnalysis()
      this.getReachTrend()
      this.getIndustryAndFloor()
      this.getCompleteRateTrend()
      this.getReachInfo()
      this.getBrandStrategy()
    },
    // 分指标达成情况 主力店/业态/楼层 切换
    indexCompleteChange (key, inx) {
      let fcode = null
      this.indexFloorList.map(item => {
        if (item.value === this.indexFloor) {
          fcode = item.fcode
        }
      })
      this.getReachInformation(inx, this.indexType, this.indexIndustry, fcode + ',' + this.indexFloor)
    },
    // 租赁决策达成趋势 收入/面积 切换
    trendChange () {
      this.getReachTrend()
    },
    // 租赁决策分析表图
    initReachChart () {
      const id = document.getElementById('reachChart')
      const chart = echarts.init(id)
      const data = Number(this.leaseAnalysis.incomeProgress)
      chart.setOption({
        series: [{
          radius: '170%',
          center: ['50%', '100%'],
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          splitNumber: 10,
          progress: {
            show: true,
            roundCap: true,
            width: 12
          },
          pointer: { show: false },
          axisLine: {
            lineStyle: {
              width: 12,
              color: [[data / 100, '#F89407'], [1, '#33353E']]
            }
          },
          axisTick: false,
          splitLine: { show: false },
          axisLabel: false,
          title: {
            offsetCenter: [0, '-10%'],
            color: '#FFF',
            fontSize: 12
          },
          detail: {
            offsetCenter: [0, '-40%'],
            color: '#F89407',
            fontSize: 24,
            formatter: function (value) {
              return value + '%'
            }
          },
          data: [{
            value: data,
            name: '收入目标达成'
          }]
        }]
      })
    },
    // 租赁决策达成趋势表图
    initTrendChart (data) {
      const id = document.getElementById('trendChart')
      const chart = echarts.init(id)
      const trend = this.trend
      const color = trend === 1 ? ['#00C567', '#00D7D5', '#F89407', '#1890FF', '#F968B0'] : ['#00D7D5', '#1890FF']
      const legend = []
      const series = []
      data.series.map((item, inx) => {
        const obj = {
          name: item.name,
          type: 'bar',
          barWidth: 12,
          itemStyle: {
            normal: { color: color[inx] }
          },
          data: item.data
        }
        if (trend === 2 && inx === 1) {
          obj.type = 'line'
          obj.yAxisIndex = 1
          obj.itemStyle.normal.color = color[1]
        }
        if (trend === 1 && inx > 1) {
          obj.type = 'line'
          obj.yAxisIndex = 1
        }
        series.push(obj)
        legend.push({
          name: item.name,
          icon: trend === 1 ? inx < 2 ? 'rect' : 'circle' : inx < 1 ? 'rect' : 'circle'
        })
      })
      chart.setOption({
        grid: {
          top: '15%',
          left: '1%',
          right: '0',
          bottom: '17%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.85)',
          extraCssText: 'box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.9)',
          formatter: (data) => {
            let text = '<div style="margin-bottom: 3px; font-size: 12px; padding-left: 15px;">' + data[0].axisValue + '</div>'
            data.map((item, inx) => {
              let text2 = ''
              if (trend === 1) {
                text2 = inx < 2 ? '万元' : '%'
              } else {
                text2 = inx < 1 ? '㎡' : '%'
              }
              text += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>' + item.data + text2 + '</div>'
            })
            return text
          }
        },
        legend: {
          bottom: '0',
          itemWidth: 14,
          itemHeight: 8,
          itemGap: 16,
          textStyle: {
            color: '#808191',
            fontSize: 12,
            padding: [2.5, 0, 0, 0]
          },
          data: legend
        },
        xAxis: {
          type: 'category',
          data: data.labels,
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' }
          },
          axisPointer: { type: 'shadow' }
        },
        yAxis: [{
          type: 'value',
          name: trend === 1 ? '金额/万元' : '已签约面积/㎡',
          splitLine: { show: false },
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          axisLabel: {
            textStyle: { color: '#808191' }
          },
          nameTextStyle: { color: '#808191' }
        }, {
          type: 'value',
          name: trend === 1 ? '达成率' : '招商完成率',
          min: 0,
          max: 100,
          interval: 20,
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#373A43'
            }
          },
          axisLabel: {
            textStyle: { color: '#808191' },
            formatter: '{value} %'
          },
          axisLine: {
            lineStyle: { color: '#373A43' }
          },
          nameTextStyle: { color: '#808191' }
        }],
        series: series
      }, true)
    },
    // 招商完成率与租决完成率趋势
    initGraphChart (data) {
      const id = document.getElementById('graphChart')
      const chart = echarts.init(id)
      const series = []
      const name = []
      data.labels.map((item, inx) => {
        series.push([])
        data.series.map(item2 => {
          name.push(item2.name)
          series[inx].push([item2.value3[inx], item2.value2[inx], item2.value1[inx], item2.name, item])
        })
      })
      const option = {
        baseOption: {
          timeline: {
            axisType: 'category',
            orient: 'vertical',
            autoPlay: true,
            inverse: true,
            playInterval: 2000,
            right: 5,
            top: 70,
            bottom: 35,
            width: 70,
            label: { color: '#808191' },
            symbol: 'none',
            lineStyle: { color: '#424752' },
            checkpointStyle: {
              color: '#F89407',
              borderColor: '#FFF',
              borderWidth: 2
            },
            controlStyle: {
              showNextBtn: false,
              showPrevBtn: false,
              color: '#808191',
              borderColor: '#808191',
              itemSize: 17
            },
            emphasis: {
              label: { color: '#F89407' },
              controlStyle: {
                color: '#F89407',
                borderColor: '#F89407',
                borderWidth: 1
              }
            },
            data: []
          },
          title: {
            text: data.labels[0],
            textAlign: 'center',
            right: '1%',
            bottom: '9%',
            textStyle: {
              fontSize: 36,
              color: '#FFF',
              fontWeight: 400
            }
          },
          grid: {
            top: 77,
            left: 0,
            right: 90,
            bottom: 40,
            containLabel: true
          },
          legend: {
            left: 0,
            type: 'scroll',
            top: '.8%',
            itemWidth: 8,
            itemHeight: 8,
            itemGap: 32,
            icon: 'circle',
            textStyle: {
              color: '#808191',
              fontSize: 14,
              padding: [1, 0, 0, 0]
            },
            pageTextStyle: { color: '#FFF' },
            pageIconColor: '#F89407',
            pageIconInactiveColor: '#2F4554',
            data: name
          },
          tooltip: {
            backgroundColor: 'rgba(0,0,0,.85)',
            extraCssText: 'box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.9)',
            formatter: (data) => {
              return '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + data.marker + data.data[3] + '</div>' +
                '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px"><span style="color: #808191">累计收入:</span>' + data.data[2] + '万元</div>' +
                '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px"><span style="color: #808191">招商完成率:</span>' + data.data[0] + '%</div>' +
                '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px"><span style="color: #808191">租决完成率:</span>' + data.data[1] + '%</div>'
            }
          },
          xAxis: {
            type: 'value',
            name: '招商完成率',
            nameLocation: 'center',
            min: 0,
            max: (value) => {
              return value.max > 100 ? Math.ceil(value.max / 100) * 100 : 100
            },
            splitLine: { show: false },
            axisLine: {
              lineStyle: { color: '#373A43' }
            },
            axisLabel: {
              textStyle: { color: '#808191' },
              formatter: '{value} %'
            },
            nameTextStyle: {
              color: '#808191',
              padding: [30, 0, 0, 0]
            }
          },
          yAxis: {
            type: 'value',
            name: '租决完成率',
            min: 0,
            max: (value) => {
              return value.max > 100 ? Math.ceil(value.max / 100) * 100 : 100
            },
            // interval: 20,
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#373A43'
              }
            },
            axisLine: {
              lineStyle: { color: '#373A43' }
            },
            axisLabel: {
              textStyle: { color: '#808191' },
              formatter: '{value} %'
            },
            nameTextStyle: { color: '#808191' }
          },
          color: ['rgba(248, 148, 7, .86)', 'rgba(249, 104, 176, .86)', 'rgba(24, 144, 255, .86)'],
          series: [{
            name: name[0],
            type: 'scatter',
            symbolSize: (value, params) => {
              const size = Number(value[1]) / 100 * 45 + 15
              params.symbolSize = size
              return params.symbolSize
            },
            data: series[0]
          }],
          animationDurationUpdate: 1000,
          animationEasingUpdate: 'quinticInOut'
        },
        options: []
      }
      for (var n = 0; n < data.labels.length; n++) {
        const optionSeries = []
        series[n].map((item, inx) => {
          const obj = {
            name: name[inx],
            type: 'scatter',
            data: [item],
            symbolSize: (value, params) => {
              const yData = Number(value[1]) > 100 ? 100 : Number(value[1])
              const size = yData / 100 * 45 + 15
              params.symbolSize = size
              return params.symbolSize
            }
          }
          optionSeries.push(obj)
        })
        option.baseOption.timeline.data.push(data.labels[n])
        option.options.push({
          title: {
            show: true,
            text: data.labels[n]
          },
          series: optionSeries
        })
      }
      chart.setOption(option, true)
    },
    // 品牌策略达成情况模块
    initColumnarChart (data) {
      const id = document.getElementById('columnarChart')
      const chart = echarts.init(id)
      const series = []
      const legend = [
        { name: 'S级', icon: 'circle' },
        { name: 'A级', icon: 'circle' },
        { name: 'B级', icon: 'circle' },
        { name: 'C级', icon: 'circle' },
        { name: 'D级', icon: 'circle' }
      ]
      // const color1 = ['#8cae9c', '#5ba9f6', '#66da7c']
      const color2 = ['#8cae9c', '#5ba9f6', '#66da7c', '#e871ae', '#346ce1']
      legend.map((item, index) => {
        const aitem = {
          name: legend[index].name,
          type: 'bar',
          stack: 'stack1',
          barWidth: 16,
          itemStyle: {
            normal: {
              color: color2[index]
            }
          },
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
        aitem.data.splice(index * 2, 2, ...data.series[0].data.slice(index * 2, index * 2 + 2))
        aitem.data.reverse()
        series.push(aitem)
      })
      // data.series.map((item, inx) => {
      //   const dataArr = []
      //   const obj = {
      //     name: item.name,
      //     stack: '总量',
      //     type: 'bar',
      //     barWidth: 16,
      //     itemStyle: {
      //       color: function (params) {
      //         const inx = params.dataIndex < 2 ? 0 : params.dataIndex < 4 ? 1 : 2
      //         return color1[inx]
      //       }
      //     },
      //     data: item.data
      //   }
      //   if (inx > 0) {
      //     obj.stack = 'stack1'
      //     obj.barWidth = 8
      //     obj.barGap = 0
      //     obj.itemStyle.color = color2[inx]
      //   }
      //   item.data.map((item2, inx2) => {
      //     let itemData = 0
      //     if (inx !== 0) {
      //       itemData = Number(item2) * Number(data.series[0].data[inx2]) / 100
      //     } else {
      //       itemData = item2
      //     }
      //     dataArr.push(itemData)
      //   })
      //   obj.data = dataArr
      //   series.push(obj)
      //   legend.push({
      //     name: item.name,
      //     icon: inx < 1 ? 'rect' : 'circle'
      //   })
      // })
      chart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: { type: 'shadow' },
        //   backgroundColor: 'rgba(0,0,0,.85)',
        //   extraCssText: 'box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.9)',
        //   formatter: (params) => {
        //     let res = ''
        //     for (let i = 0; i < params.length; i++) {
        //       const item = params[i]
        //       if (i === 0) {
        //         res += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>' + item.data + '% </div>'
        //       } else {
        //         const data = Math.abs(item.data) === 0 ? 0 : Math.round(Math.abs(item.data) / Math.abs(params[0].data) * 100 * 100) / 100
        //         res += '<div style="margin-bottom: 3px; font-size: 12px; padding: 0 3px">' + item.marker + '<span style="color: #808191">' + item.seriesName + '：</span>' + data + '% </div>'
        //       }
        //     }
        //     return res
        //   }
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let res = ''
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesId.indexOf(params[i].name.trim()[0]) !== -1) {
                res = '<h5>' + params[i].name + '</h5>' + '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' + params[i].color + '"></span>' + params[i].data + '%'
                break
              }
            }
            return res
          }
        },
        legend: {
          textStyle: {
            color: '#808191',
            fontSize: 12,
            padding: [3, 0, 0, 0]
          },
          bottom: 0,
          itemWidth: 14,
          itemHeight: 8,
          data: legend
        },
        grid: {
          top: '0',
          left: '1%',
          right: '9%',
          bottom: '12%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '数量占比',
          splitLine: {
            lineStyle: {
              color: ['rgba(226,226,226,.1'],
              width: 1
            }
          },
          nameTextStyle: {
            color: '#808191',
            padding: [30, 0, 0, 0]
          },
          axisLabel: {
            color: '#808191',
            formatter: function (value) {
              return value + '%'
            }
          }
        },
        yAxis: {
          axisLabel: { color: '#808191' },
          data: data.labels.reverse(),
          type: 'category'
        },
        series: series
      })
    }
  }
}
</script>

<style lang="stylus">
.leasing-decision
  position relative
.leasingAnalysis
  .el-input__icon
    line-height 36px
  .lease-trend-content
    .el-radio__input
      height 16px
    .el-radio__inner
      width 16px
      height 16px
      border none !important
      background rgba(255, 255, 255, 0.33)
    .is-checked
      .el-radio__inner
        background #F89407 !important
      .el-radio__label
        color #FFF !important
    .el-radio__label
      color #808191
      padding-left 6px
    .el-radio__inner::after
      width 10px
      height 10px
  .native-input-solid
    width 158px
    .el-input__inner
      border none
      background #373A43 !important
  .el-progress
    margin-left 30px
    .el-progress__text
      color #FFF
    .el-progress-circle__track
      stroke #373A43
</style>
<style lang="stylus" scoped>
.leasingAnalysis
  font-size 14px
  .select-component
    position absolute
    margin-top 12px
    top 0
    right 290px
    width 204px
  .f-wrap
    flex-wrap wrap
  .d-flex
    display flex
  .content-between
    justify-content space-between
  .title
    font-size 16px
    font-weight bold
    width 280px
    padding 3px 16px
    background linear-gradient(94deg, #684D31 0%, rgba(52, 39, 25, 0) 100%)
  .second-title
    font-size 16px
    line-height 40px
  .second-title:before
    content ''
    display inline-block
    width 4px
    height 14px
    background #F89407
    vertical-align middle
    position relative
    bottom 1px
    margin-right 4px
  .data-detail-list, .data-list li
    display flex
    justify-content space-between
    span
      color #808191
  .data-detail-list
    width 340px
    padding 16px 32px 16px 16px
    border-radius 2px
    border 1px solid #33353E
    border-bottom none
    span
      display block
      margin-bottom 10px
  .data-detail-list:last-child
    border-bottom 1px solid #33353E
  .lease-analysis, .lease-trend, .index-complete, .brand-complete
    background #272930
    padding-left 2px
  .lease-content
    .lease-analysis
      width 39%
      min-width 629px
      margin-right 24px
      .lease-analysis-content
        padding 33px 30px 40px
        .right-data
          margin-left 5%
          .data-detail-list
            width 280px
        #reachChart
          width 260px
          height 108px
        .data-list
          margin-top 20px
          li:not(:last-child)
            margin-bottom 12px
          li:nth-child(2)
            margin-bottom 24px
            padding-bottom 24px
            border-bottom 1px solid #373A43
    .lease-trend
      width 59.5%
  .lease-trend
    .lease-trend-content
      padding 16px 31px 17px
      div
        justify-content flex-end
      #trendChart
        width 100%
        height 310px
  .index-complete, .brand-complete
    margin-top 24px
  .index-complete
    .rent-list
      padding 32px 10px
      min-width 571px
      width 35.5%
      li
        padding 4.3%
        margin-bottom 16px
        border 1px solid #383A41
        border-radius 2px
        box-shadow 0px 6px 30px rgba(0, 0, 0, 0.5)
        .rent
          p
            margin-bottom 7px
            .up, .down
              margin 0 1px 0 8px
              color #FF754C
            .up:before, .down:before
              content ''
              display inline-block
              width 0
              height 0
              border 6px solid
              position relative
              border-color transparent transparent #FF754C
            .down
              color #00C567
            .down:before
              border-color #00C567 transparent transparent
              top 5px
            .up:before
              bottom 2px
            span
              color #808191
      li:last-child
        margin 0
  .graph>div:first-child
    padding-right 18px
  .graph
    width 59%
    padding 32px 0 32px 8px
    position relative
    .tool-bar
      .native-input-solid
        position relative
        z-index 10
        .el-select
          .el-input__inner
            height 36px
            color #808191
    #graphChart
      width 100%
      height 975px
  .brand-complete
    .second-title
      margin-bottom 24.5px
    .brand-complete-content
      padding 32px
    .right-chart-data
      width 71%
      #columnarChart
        width 100%
        height 450px
</style>
