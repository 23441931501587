<template>
  <section class="complete-charts">
    <ul class="charts-list">
      <li
        v-for="(item, inx) in dataList.percent"
        :key="inx"
      >
        <div class="total">
          <p
            class="now-complete"
            :style="Number(item) > 100 ? 'height: 112px' : 'height:' + 112 * (Number(item) / 100) + 'px'"
          >
            <span class="percent">{{item}}%</span>
          </p>
        </div>
        <div
          class="detail-data"
          v-if="dataList.data"
        >
          {{dataList.data[inx] + dataName[inx].unit}}
          <span>{{dataName[inx].name}}</span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  data () {
    return {
      dataName: [
        { name: '目标面积', unit: '㎡' },
        { name: '目标铺位', unit: '个' },
        { name: '目标租金', unit: '万元' },
        { name: '目标管理费', unit: '万元' },
        { name: '目标收入', unit: '万元' }
      ],
      dataList: {
        data: [],
        percent: []
      }
    }
  },
  props: {
    dataArr: Object
  },
  watch: {
    dataArr: function (arr) {
      var data = arr
      this.dataList.data = [data.area, data.berthNums, data.rentTarget, data.costTarget, data.incomeTarget]
      this.dataList.percent = [data.areaRate, data.berthNumsRate, data.rentTargetRate, data.costTargetRate, data.incomeTargetRate]
    }
  },
  created () {
    var _this = this
    setTimeout(() => {
      var data = _this.dataArr
      _this.dataList.data = [data.area, data.berthNums, data.rentTarget, data.costTarget, data.incomeTarget]
      _this.dataList.percent = [data.areaRate, data.berthNumsRate, data.rentTargetRate, data.costTargetRate, data.incomeTargetRate]
    }, 500)
  }
}
</script>

<style lang="stylus" scoped>
.complete-charts
  margin-top 48px
  .charts-list
    display flex
    justify-content space-between
    .total
      width 56px
      height 112px
      background #353539
      position relative
      .now-complete
        position absolute
        width 100%
        height 0
        bottom 0
        text-align center
        background #20D6FE
        transition all 0.5s ease
        .percent
          position absolute
          top -20px
          width 100%
          left 0
          right 0
          font-size 12px
    .detail-data
      font-size 14px
      margin-top 16px
      span
        display block
        font-size 14px
        color #808191
</style>
